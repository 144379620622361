import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, useToast } from '@chakra-ui/react';

import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import { EMPLOYEE_DETAILS_BREADCRUMB } from '../../constants/breadcrumbData';
import EmployeeManagement from '../../service/employee-management';
import PartnerManagement from '../../service/partner-management';
import PersonalInfo from '../components/EmployeeDetailsComponents/PersonalInfo';
import EmployeeEditModal from '../components/EmployeeEditModal';

export default function EmployeeDetailsNewDesign() {
    const PAGE_TITLE = 'Employee Details';

    const toast = useToast();
    const { id } = useParams();
    const [employee, setEmployee] = useState();
    const [isEmployeeEditModalOpen, setEmployeeEditModalOpen] = useState(false);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [supervisor, setSupervisor] = useState('');
    const [partners, setPartners] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [isEmployeeUpdated, setIsEmployeeUpdated] = useState(false);

    const fetchDesignation = async () => {
        try {
            const response = await EmployeeManagement.getAllDesignation();
            const designations = response.content.map(d => d.name);
            setDesignations(designations);
        } catch (error) {
            toast({
                title: 'Designation fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchPartners = async () => {
        try {
            const response = await PartnerManagement.getAllPartner();
            setPartners(response.data);
            setDataLoaderOpen(false);
        } catch (error) {
            toast({
                title: 'Partner fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchDesignation();
        fetchPartners();
    }, []);

    // Fetching employee details and supervisors via API call
    useEffect(() => {
        setIsEmployeeUpdated(false);
        const fetchData = async () => {
            const response1 = await EmployeeManagement.getEmployeeDetails(id);
            const response2 = await EmployeeManagement.getEmployeeDetails(
                response1.supervisorId
            );
            setEmployee(response1);
            setSupervisor(response2);
        };

        fetchData().catch(error => {
            toast({
                title: 'Data Fetching Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });
    }, [isEmployeeUpdated, isDataLoaderOpen]);

    if (isDataLoaderOpen || isEmployeeUpdated || !employee)
        return <DataLoader isOpen={isDataLoaderOpen} />;

    if (isEmployeeEditModalOpen) {
        return (
            <EmployeeEditModal
                isOpen={isEmployeeEditModalOpen}
                onClose={() => setEmployeeEditModalOpen(false)}
                employee={employee}
                supervisor={supervisor}
                partnerList={partners}
                designationList={designations}
                setDataLoaderOpen={setDataLoaderOpen}
                setIsEmployeeUpdated={setIsEmployeeUpdated}
            />
        );
    }

    return (
        <Box>
            <Box bgColor="#FFFFFF">
                <CustomBreadcrumb
                    allBreadcrumbData={EMPLOYEE_DETAILS_BREADCRUMB}
                />
            </Box>

            <Box direction="row" my="16px">
                <PageTitle title={PAGE_TITLE} />
            </Box>

            <Box ml={[0, 0, 0, 0, '16%']}>
                <PersonalInfo
                    results={employee}
                    partnerList={partners}
                    designationList={designations}
                    supervisor={supervisor}
                    isEmployeeEditModalOpen={isEmployeeEditModalOpen}
                    setEmployeeEditModalOpen={setEmployeeEditModalOpen}
                />
            </Box>
        </Box>
    );
}
