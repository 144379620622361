export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const IS_TOKEN_FOUND = 'IS_TOKEN_FOUND';
export const CLEAR_INFORMATION = 'CLEAR_INFORMATION';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const SET_LAST_LOGIN_TIME = 'SET_LAST_LOGIN_TIME';
export const SET_GOOGLE_LOGIN_TOKEN_ID = 'SET_GOOGLE_LOGIN_TOKEN_ID';
export const SET_ALL_DESIGNATION = 'SET_ALL_DESIGNATION';
export const SET_MENUBAR_WIDTH = 'SET_MENUBAR_WIDTH';
export const IS_MENUBAR_OPEN = 'IS_MENUBAR_OPEN';

export const FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE =
    'FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE';
export const FILTER_DESIGNATION_FOR_LEAVE = 'FILTER_DESIGNATION_FOR_LEAVE';
export const ADD_EMPLOYEE_LEAVE_RESULT = 'ADD_EMPLOYEE_LEAVE_RESULT';
export const IS_FILTER_OPTION_OPEN_FOR_LEAVE =
    'IS_FILTER_OPTION_OPEN_FOR_LEAVE';
export const IS_UPDATED_DATE_RANGE_FOR_FILTER =
    'IS_UPDATED_DATE_RANGE_FOR_FILTER';
