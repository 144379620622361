import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AddAdmin from './admin/containers/AddAdmin';
import AuthContainer from './auth/containers/AuthContainer';
import ProtectedRoute from './common/ProtectedRoute';
import { SET_MENUBAR_WIDTH } from './constants/actionTypeConstant';
import * as Type from './constants/actionTypeConstant';
import * as route from './constants/pathNameConstants';
import { useInformation } from './contexts/informationContext';
import AddEmployee from './employee/containers/AddEmployee';
import EmployeeDetails from './employee/containers/EmployeeDetails';
import EmployeeList from './employee/containers/EmployeeList';
import EmployeeLeaveDetails from './report/containers/EmployeeLeaveDetails';
import LeaveList from './report/containers/LeaveList';
import TeamDetails from './team/containers/TeamDetails';
import TeamList from './team/containers/TeamList';

function App() {
    const { state, dispatch } = useInformation();
    const location = useLocation();

    useEffect(() => {
        if (state.isMenubarOpen) {
            dispatch({ type: SET_MENUBAR_WIDTH, payload: '280px' });
        }

        return () => {
            dispatch({
                type: Type.FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE,
                payload: [],
            });
            dispatch({
                type: Type.IS_FILTER_OPTION_OPEN_FOR_LEAVE,
                payload: false,
            });
            dispatch({
                type: Type.FILTER_DESIGNATION_FOR_LEAVE,
                payload: [],
            });
            dispatch({
                type: Type.ADD_EMPLOYEE_LEAVE_RESULT,
                payload: { content: [] },
            });
        };
    }, [location.pathname]);

    return (
        <Routes>
            <Route exact path={route._ROOT} element={<AuthContainer />} />
            <Route path={route.LOGIN_PATH} element={<AuthContainer />} />
            <Route
                path={route.LOGIN_PATH}
                element={
                    <Navigate
                        to={route.buildEmployeesPath(0, 10)}
                        replace={true}
                    />
                }
            />
            {/* Employee Management */}
            <Route
                path={route.EMPLOYEE_ADD_PATH}
                element={
                    <ProtectedRoute>
                        <AddEmployee />
                    </ProtectedRoute>
                }
            />
            <Route
                path={route.EMPLOYEE_PATH}
                element={
                    <ProtectedRoute>
                        <EmployeeDetails />
                    </ProtectedRoute>
                }
            />
            <Route
                path={route.EMPLOYEES_PATH}
                element={
                    <ProtectedRoute>
                        <EmployeeList />
                    </ProtectedRoute>
                }
            />
            {/* // Teams management route */}
            <Route
                path={route.TEAMS_PATH}
                element={
                    <ProtectedRoute>
                        <TeamList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={route.TEAM_DETAILS_PATH}
                element={
                    <ProtectedRoute>
                        <TeamDetails />
                    </ProtectedRoute>
                }
            />
            {/*Leave Report Details*/}
            <Route
                path={route.LEAVES_PATH}
                element={
                    <ProtectedRoute>
                        <LeaveList />
                    </ProtectedRoute>
                }
            />
            <Route
                path={route.LEAVE_PATH}
                element={
                    <ProtectedRoute>
                        <EmployeeLeaveDetails />
                    </ProtectedRoute>
                }
            />
            {/* // Admin Management Route */}
            <Route
                path={route.ADMIN_ADD_PATH}
                element={
                    <ProtectedRoute>
                        <AddAdmin />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}

export default App;
