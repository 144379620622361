import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Spacer, Text, useToast } from '@chakra-ui/react';

import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import DownloadListTab from '../../common/DownloadListTab';
import PageTitle from '../../common/PageTitle';
import * as Type from '../../constants/actionTypeConstant';
import { LEAVE_LIST_BREADCRUMB } from '../../constants/breadcrumbData';
import { PERMANENT } from '../../constants/employeeTypes';
import { buildLeavesPath } from '../../constants/pathNameConstants';
import { NUMBER_OF_EMPLOYEE_FILTERED_BY_NAME } from '../../constants/reportManagementConstants';
import { useInformation } from '../../contexts/informationContext';
import { currentDate, startingFromCurrentYear } from '../../helper/utils';
import EmployeeManagement from '../../service/employee-management';
import PartnerManagement from '../../service/partner-management';
import ReportManagement from '../../service/reports-management';
import EmployeeLeaveResults from '../components/EmployeeLeaveResults';
import FilterOptions from '../components/FilterOptions';
import FilterPicker from '../components/FilterPicker';
import FilteredData from '../components/FilteredData';
import LeaveReportDateFilter from '../components/LeaveReportDateFilter';

const PAGE_TITLE = 'Employee Leave Details';

const LeaveList = () => {
    const {
        state: {
            filterByEmployeesNameForLeave,
            isFilterOptionOpenForLeave,
            filterByDesignationForLeave,
            employeesLeaveResult,
        },
        dispatch,
    } = useInformation();
    const navigate = useNavigate();
    const toast = useToast();
    let { page, size } = useParams();
    const [leaveResults, setLeaveResult] = useState(employeesLeaveResult);
    const [employeesByName, setEmployeesByName] = useState([]);
    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState(null);
    const [designations, setDesignations] = useState([]);
    const [dateRange, setDateRange] = useState([
        startingFromCurrentYear(),
        currentDate(),
    ]);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [csvData, setCsvData] = useState(false);
    const csvInstance = useRef();
    const partnerId = partner
        ? partners.find(p => p.name === partner).id
        : null;

    const onDateRangeChangeHandler = dateRange => {
        setDateRange(dateRange);
    };

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current.link) {
            csvInstance.current.link.click();
            setCsvData(false);
        }
    }, [csvData]);

    const exportCsv = async () => {
        const employeesId = filterByEmployeesNameForLeave.map(
            e => e.employeeId
        );
        try {
            const payload = {
                startFrom: dateRange[0],
                endAt: dateRange[1],
                employeeIdList: employeesId,
                designationList: filterByDesignationForLeave,
                partnerId: partnerId,
            };
            const response = await ReportManagement.leaveReportDownload(
                payload
            );
            const leaveResultObject = [];
            for (let i = 0; i < response.length; i++) {
                const {
                    employeeName,
                    employeeId,
                    designation,
                    partner,
                    totalLeave,
                    unplannedLeave,
                    monthlyAverageLeave,
                    yearlyForecastedLeave,
                    leaveComparison,
                } = response[i];

                const individualRow = {
                    ['Employee Name']: employeeName,
                    ['Employee Id']: employeeId,
                    ['Designation']: designation,
                    ['Partner']: partner.name,
                    ['Total Leave']: totalLeave,
                    ['Unplanned Leave']: unplannedLeave,
                    ['Monthly Average']: monthlyAverageLeave,
                    ['Yearly Forecast']: yearlyForecastedLeave,
                    ['Leave Comparison']: leaveComparison + '%',
                };
                leaveResultObject.push(individualRow);
            }
            setCsvData(leaveResultObject);
        } catch (error) {
            setCsvData([]);
        }
    };

    const searchPartnersAndDesignations = async () => {
        try {
            const allPartners = await PartnerManagement.getAllPartner();
            setPartners(allPartners.partners);

            const allDesignations =
                await EmployeeManagement.getAllDesignation();
            setDesignations(allDesignations.content.map(d => d.name));
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        searchPartnersAndDesignations();
    }, []);

    const setSelectedPartner = partner => {
        setPageNumber(0);
        setPartner(partner);
    };

    const setNameListQueryParameter = employeeId => {
        let newNameList = [...filterByEmployeesNameForLeave];
        const employeeObj = employeesByName.find(
            e => e.employeeId === employeeId
        );

        const foundObject = newNameList.find(e => e.employeeId === employeeId);
        if (!foundObject) {
            const newObject = {
                employeeId: employeeObj.employeeId,
                employeeName: employeeObj.name,
            };
            newNameList.push(newObject);
        }
        setPageNumber(0);
        dispatch({
            type: Type.FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE,
            payload: newNameList,
        });
    };

    const removeNameFromNameListQueryParameter = employeeId => {
        dispatch({
            type: Type.FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE,
            payload: filterByEmployeesNameForLeave.filter(
                employee => employeeId !== employee.employeeId
            ),
        });
    };

    const setDesignationListQueryParameter = desg => {
        let newDesignationList = [...filterByDesignationForLeave];
        const designationField = newDesignationList.find(d => d === desg);
        if (!designationField) {
            newDesignationList.push(desg);
        }
        setPageNumber(0);
        dispatch({
            type: Type.FILTER_DESIGNATION_FOR_LEAVE,
            payload: newDesignationList,
        });
    };

    const removeDesignationFromDesignationListQueryParameter = desg => {
        dispatch({
            type: Type.FILTER_DESIGNATION_FOR_LEAVE,
            payload: filterByDesignationForLeave.filter(d => desg !== d),
        });
    };

    async function employeeSearchByName(name) {
        if (name.length >= 3) {
            const response =
                await EmployeeManagement.getEmployeesByNameAndStatus(
                    name,
                    PERMANENT,
                    0,
                    NUMBER_OF_EMPLOYEE_FILTERED_BY_NAME
                );
            setEmployeesByName(response.content);
        }
    }

    useEffect(() => {
        getEmployeeLeaveListDetails();

        return () => {
            dispatch({
                type: Type.ADD_EMPLOYEE_LEAVE_RESULT,
                payload: { content: [] },
            });
        };
    }, [
        page,
        size,
        dateRange,
        filterByEmployeesNameForLeave,
        filterByDesignationForLeave,
        partnerId,
    ]);

    async function getEmployeeLeaveListDetails() {
        if (!page) return;
        const employeesId = filterByEmployeesNameForLeave.map(
            e => e.employeeId
        );
        setDataLoaderOpen(true);
        const payload = {
            page: page,
            size: size,
            startFrom: dateRange[0],
            endAt: dateRange[1],
            employeeIdList: employeesId,
            designationList: filterByDesignationForLeave,
            partnerId: partnerId,
        };
        try {
            const response = await ReportManagement.leaveReportDetails(payload);
            setLeaveResult(response);
            dispatch({
                type: Type.ADD_EMPLOYEE_LEAVE_RESULT,
                payload: response,
            });
            setDataLoaderOpen(false);
            navigate(buildLeavesPath(page, size));
        } catch (error) {
            setDataLoaderOpen(false);
            dispatch({
                type: Type.ADD_EMPLOYEE_LEAVE_RESULT,
                payload: { content: [] },
            });
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    function setPageNumber(pageNumber) {
        page = pageNumber;
        navigate(buildLeavesPath(page, size));
    }

    if (!leaveResults) return <DataLoader isOpen={isDataLoaderOpen} />;

    return (
        <Box>
            <CustomBreadcrumb allBreadcrumbData={LEAVE_LIST_BREADCRUMB} />

            <Flex direction="row">
                <PageTitle title={PAGE_TITLE} />

                <Spacer />

                <DownloadListTab exportCsv={exportCsv} />

                {csvData ? (
                    <CSVLink
                        uFEFF={false}
                        data={csvData}
                        filename={`aladdin-leaves-report.csv`}
                        className="hidden"
                        ref={csvInstance}
                        target="_blank"
                    />
                ) : null}

                <Flex
                    direction={{ lg: 'row', md: 'row', base: 'column' }}
                    my="16px"
                >
                    <LeaveReportDateFilter
                        onChange={onDateRangeChangeHandler}
                    />
                    <FilterPicker
                        isOpenFilterOption={isFilterOptionOpenForLeave}
                        setSelectedPartner={setSelectedPartner}
                    />
                </Flex>
            </Flex>

            {isFilterOptionOpenForLeave ? (
                <Box>
                    <FilterOptions
                        nameList={filterByEmployeesNameForLeave}
                        employeesByName={employeesByName}
                        setEmployeesByName={setEmployeesByName}
                        setNameListQueryParameter={setNameListQueryParameter}
                        employeeSearchByName={employeeSearchByName}
                        partners={partners}
                        selectedPartner={partner}
                        setSelectedPartner={setSelectedPartner}
                        selectedDesignationsLength={
                            filterByDesignationForLeave.length
                        }
                        designationsList={designations}
                        setDesignationListQueryParameter={
                            setDesignationListQueryParameter
                        }
                    />
                    <FilteredData
                        nameList={filterByEmployeesNameForLeave}
                        removeNameFromNameListQueryParameter={
                            removeNameFromNameListQueryParameter
                        }
                        selectedPartner={partner}
                        setSelectedPartner={setSelectedPartner}
                        designationList={filterByDesignationForLeave}
                        removeDesignationFromDesignationListQueryParameter={
                            removeDesignationFromDesignationListQueryParameter
                        }
                    />
                </Box>
            ) : null}
            <Text fontSize="12px" fontWeight="300" mt={2} mb={2}>
                Showing result for{' '}
                <b>
                    {dateRange[0]} - {dateRange[1]}
                </b>
            </Text>
            <EmployeeLeaveResults results={employeesLeaveResult} />
            {employeesLeaveResult.totalPages > 1 && (
                <CommonPagination
                    totalPages={employeesLeaveResult.totalPages ?? 1}
                    setPageNo={setPageNumber}
                    page={Number(page)}
                    size={Number(size)}
                />
            )}
            <Box mt={2} mb={7}>
                {employeesLeaveResult?.content?.length > 0 ? (
                    <Text fontSize="12px" fontWeight="300">
                        <b>Current Company Average </b>=
                        {` ${employeesLeaveResult.content[0].companyAverageLeave} `}
                        Days leave/year
                    </Text>
                ) : null}
            </Box>
        </Box>
    );
};

export default LeaveList;
