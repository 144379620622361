import { useState } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Box,
    Divider,
    Flex,
    Image,
    Stack,
    useColorModeValue as mode,
} from '@chakra-ui/react';

import leftArrow from '../assets/icons/left-arrow.svg';
import rightArrow from '../assets/icons/right-arrow.svg';
import {
    IS_MENUBAR_OPEN,
    SET_MENUBAR_WIDTH,
} from '../constants/actionTypeConstant';
import * as color from '../constants/colorCode';
import MENUBAR_DATA from '../constants/menubarData';
import { useInformation } from '../contexts/informationContext';
import NavLink from './NavLink';

function Menubar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { state, dispatch } = useInformation();
    const [arrowIcon, setArrowIcon] = useState(leftArrow);
    const [arrowButtonDisplay, setArrowButtonDisplay] = useState('none');
    const [openChildNav, setOpenChildNav] = useState(false);

    function onClickIconChangeHandler() {
        if (state.isMenubarOpen) {
            setArrowIcon(rightArrow);
            dispatch({ type: SET_MENUBAR_WIDTH, payload: '70px' });
            dispatch({ type: IS_MENUBAR_OPEN, payload: false });
        } else {
            setArrowIcon(leftArrow);
            dispatch({ type: SET_MENUBAR_WIDTH, payload: '280px' });
            dispatch({ type: IS_MENUBAR_OPEN, payload: true });
        }
    }

    function printNavLink(item, index) {
        if (!item.child) {
            return (
                <NavLink
                    key={index}
                    label={state.isMenubarOpen ? item.label : ''}
                    lShow={''}
                    icon={item.icon}
                    color={''}
                    onClick={
                        item.path !== null ? () => navigate(item.path) : ''
                    }
                    isActive={
                        item.path !== null
                            ? location.pathname.includes(
                                  item.lShow.toLowerCase()
                              )
                            : ''
                    }
                />
            );
        }

        return (
            <React.Fragment key={index}>
                <NavLink
                    label={state.isMenubarOpen ? item.label : ''}
                    lShow={''}
                    icon={item.icon}
                    color={''}
                    onClick={() => {
                        setOpenChildNav(prev => !prev);
                    }}
                />
                {openChildNav ? (
                    <NavLink
                        label={state.isMenubarOpen ? item.child[0].label : ''}
                        lShow={''}
                        icon={item.child[0].icon}
                        color={''}
                        onClick={
                            item.child[0].path !== null
                                ? () => navigate(item.child[0].path)
                                : ''
                        }
                        isActive={
                            item.child[0].path !== null
                                ? location.pathname.includes(
                                      item.child[0].lShow.toLowerCase()
                                  )
                                : ''
                        }
                    />
                ) : null}
            </React.Fragment>
        );
    }

    return (
        <Flex
            width={state.menubarWidth}
            direction="column"
            borderRightWidth="1px"
            borderRightColor={mode('gray.200', 'gray.700')}
            flexShrink={0}
            flexGrow={0}
            pb={6}
            bg={color.SIDEBAR_BG_COLOR}
            zIndex={100}
            position="relative"
            onMouseEnter={() => setArrowButtonDisplay('inline')}
            onMouseLeave={() => setArrowButtonDisplay('none')}
        >
            <Box
                mt="80px"
                position="absolute"
                right={-2.5}
                onClick={onClickIconChangeHandler}
                bg={color.SIDEBAR_WHITE_ROUND_BOX_BG_COLOR}
                borderRadius="50%"
                px={2}
                py={1.5}
                cursor="pointer"
                display={arrowButtonDisplay}
                boxShadow={
                    'rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px'
                }
            >
                <Image w="8px" src={arrowIcon} />
            </Box>
            <Stack pt={20} spacing={6} mt={8}>
                <Stack spacing={2}>
                    {MENUBAR_DATA.map((data, index) => {
                        return printNavLink(data, index);
                    })}
                </Stack>
                <Divider />
            </Stack>
        </Flex>
    );
}

export default Menubar;
